import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { OptionsProvider, useOptions } from "./containers/OptionsContext";
import { UserProvider, useUser } from "containers/UsersContext";

function App() {
  const { foundSystemValue } = useOptions();


  return (
    <HelmetProvider>
      <Helmet>
        <title>{foundSystemValue}</title>
        <meta name="description" content="Ponchaishop" />
      </Helmet>
      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default function WrappedApp() {
  return (
    <OptionsProvider>
      <UserProvider>
        <App />
      </UserProvider>

    </OptionsProvider>
  );
}
