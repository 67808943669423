import React,{useEffect,useState} from 'react'

interface ImageData {
  id: number; // หรือ number ถ้า id เป็น number
  link: string;
  url: string;
}

const PromotionImages2Mini: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);

  useEffect(() => {
    fetch('/data/imagePromotion2Mini.json')
      .then((response) => response.json())
      .then((data) =>{
        setImages(data.slice(0,2));
      })
      .catch((error) => {
        console.error('Error Fetching',error);
      })
  },[]);

  return (
    <div className="flex flex-col overflow-hidden space-y-1">
      {images.map((image) => (
        <a key={image.id} href={image.link}>
          <img
          className="w-72 h-auto rounded-lg hover:opacity-70 transition-opacity duration-200 object-cover"
          src={image.url}
          alt=''
          />
        </a>
      ))}
    </div>

  )
}

export default PromotionImages2Mini

