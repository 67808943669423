import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            เปลี่ยนรหัสผ่าน
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>รหัสผ่านปัจจุบัน</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>รหัสผ่านใหม่</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>ยืนยันรหัสผ่าน</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary>
                <i className="las la-edit mr-2 mb-1"></i>
                เปลี่ยนรหัสผ่าน
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
