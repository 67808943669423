import React, { useState, useEffect } from 'react';
import Input from 'shared/Input/Input';

interface PopupAddProps {
    togglePopup: () => void;
    isOpen: boolean;
}

const PopupAdd: React.FC<PopupAddProps> = ({ togglePopup, isOpen }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsVisible(true), 30);
        } else {
            setIsVisible(false);
        }
    }, [isOpen]);

    return (
        
        <div className={`fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-white/80 pt-[17rem] md:pt-0 overflow-auto transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            <div className={`bg-slate-50 dark:bg-slate-800  px-6 py-7 border border-t-4 border-t-red-500 space-y-4 sm:space-y-6 rounded-lg w-full max-w-[56rem] shadow-lg transform transition-all duration-500 ease-out ${isVisible ? ' opacity-100' : 'opacity-0'}`}>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                        <i className="text-2xl mr-2 font-bold las la-home"></i>
                        <h2 className='text-xl font-medium'>เพิ่มที่อยู่ใหม่</h2>
                    </div>
                    <button onClick={togglePopup}>
                        <i className="text-3xl las la-times-circle"></i>
                    </button>
                </div>

                {/* Remaining content unchanged */}
                <div className=" grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            ชื่อจริง
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-user"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            นามสกุล
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-user"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                </div>
                <div>
                    <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                        เบอร์โทรศัพท์
                    </label>
                    <div className="mt-1.5 flex w-full md:w-1/2 ">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                            <i className="text-2xl las la-phone"></i>
                        </span>
                        <Input className="!rounded-l-none" />
                    </div>
                </div>

                <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                    <div className="flex-1">
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            รายละเอียดที่อยู่
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-map-marked-alt"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                    <div className="sm:w-1/3">
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            ชั้น, ตึก *
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-building"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            ตำบล
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-hotel"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            อำเภอ
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-hotel"></i>
                            </span>
                            <select
                                className="h-11 px-4 py-3 text-sm font-normal block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800"
                                defaultValue="United States"
                            >
                                <option value="United States">United States</option>
                                <option value="Canada">Canada</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Israel">Israel</option>
                                <option value="France">France</option>
                                <option value="England">England</option>
                                <option value="Laos">Laos</option>
                                <option value="China">China</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            จังหวัด
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-city"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                    <div>
                        <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                            รหัสไปรษณีย์
                        </label>
                        <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                <i className="text-2xl las la-map-signs"></i>
                            </span>
                            <Input className="!rounded-l-none" />
                        </div>
                    </div>
                </div>

                <div>
                    <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                        ประเภทที่อยู่
                    </label>
                    <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                        <div className="flex items-center text-sm sm:text-base">
                            <input
                                id="Address-type-home"
                                name="Address-type"
                                type="radio"
                                className="text-primary-500 rounded-full border-slate-400 dark:border-slate-700 w-6 h-6"
                                value="Address-type-home"

                            />
                            <label
                                htmlFor="Address-type-home"
                                className="pl-2.5 sm:pl-3 block text-slate-900 dark:text-slate-100"
                            >
                                บ้าน <span className="font-light">(จัดส่งทั้งวัน)</span>
                            </label>
                        </div>
                        <div className="flex items-center text-sm sm:text-base">
                            <input
                                id="Address-type-office"
                                name="Address-type"
                                type="radio"
                                className="text-primary-500 rounded-full border-slate-400 dark:border-slate-700 w-6 h-6"
                                value="Address-type-office"

                            />
                            <label
                                htmlFor="Address-type-office"
                                className="pl-2.5 sm:pl-3 block text-slate-900 dark:text-slate-100"
                            >
                                ที่ทำงาน <span className="font-light">(จัดส่งในเวลางาน)</span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex flex-col sm:flex-row pt-6">
                    <button className="nc-Button bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 rounded-full py-3 px-6 hover:bg-slate-800">
                        <i className="las la-plus-circle mr-2 mb-1"></i>
                        เพิ่มที่อยู่
                    </button>
                    <button onClick={togglePopup} className="nc-Button mt-3 sm:mt-0 sm:ml-3 bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-300 rounded-full py-3 px-6 hover:bg-gray-100">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupAdd;
