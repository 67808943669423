import React, { useEffect, useState } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Category from "components/Custom-Category/category";

import { Product } from "data/data";
import MultiLevelDropdown from "components/MultiLevelDropdown";
import Promobar from "components/Promobar";
import PromotionImageSlider from "components/PromotionImageSlider";
import PromotionImages2Mini from "components/PromotionImages2Mini";
import DropdownCategory from "components/DropdownCategory";
import PromotionImages from "components/PromotionImages";
import ProductCardCustom from "components/ProductCardCustom";
import SectionFlashSale from "components/SectionFlashSale";
import SectionSaleCustom from "components/SectionSaleCustom";
import SectionSliderProductCardCustom from "components/SectionSliderProductCardCustom";
import SectionSliderLargeProduct2 from "components/SectionSliderLargeProduct2";
import PromotionImageSlider2 from "components/PromotionImageSlider2";
import SectionBuyAgain from "components/SectionBuyAgain";
import MultilevelDropdownMobile from "components/MultilevelDropdownMobile";
import MultilevelDropdownHorizontal from "components/MultilevelDropdownHorizontal";

function PageHome() {
 

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Promobar />
      <div className="container relative space-y-4 my-2 lg:space-y-22 lg:my-22">
        <div className="flex flex-col lg:flex-row">
          <DropdownCategory />
          <PromotionImages />
        </div>
        <SectionFlashSale />
        <MultilevelDropdownHorizontal />
        <PromotionImageSlider2 />
        <SectionBuyAgain />

        <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div>

        <SectionGridFeatureItems />

        <SectionPromo2 />

        <SectionSliderLargeProduct cardStyle="style2" />

        <SectionSliderCategories />

      </div>
    </div>
  );
}

export default PageHome;
