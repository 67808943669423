import React, { FC, useEffect, useState } from "react";
import MainNav2Logged from "./MainNav2Logged";
import { useOptions } from "containers/OptionsContext";
import { useUser } from "containers/UsersContext";
import { useNavigate } from "react-router-dom";
import AlertService from "components/AlertServicce";

export interface HeaderLoggedProps { }

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const { getOptionByName } = useOptions();
  const systemContactUsTell = getOptionByName("systemContactUsTell");
  const { user, setUser } = useUser();
  const navigate = useNavigate(); // ใช้ useNavigate สำหรับเปลี่ยนหน้า


  const [isTopBarVisible, setIsTopBarVisible] = useState(true);
  const [isNavSticky, setIsNavSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsTopBarVisible(false);
    } else {
      setIsTopBarVisible(true);
    }

    if (window.scrollY > 100) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const logout = () => {
    setUser(null);
    AlertService.showSuccess('ออกจากระบบสำเร็จ!', 'คุณได้ออกจากระบบเรียบร้อยแล้ว', 2000);
    setTimeout(() => {
        navigate('/login');
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Top bar */}
      <div
        className={`fixed top-0 nc-HeaderLogged w-full z-40 ${isTopBarVisible ? "static opacity-100" : "opacity-0"
          }`}
      >
        <div className="pt-1 px-2 bg-[#f5f7f8] dark:bg-slate-800 hidden lg:flex top-0 w-full z-40">
          <div className="container flex justify-between items-center">
            <p className="text-xs font-medium text-black dark:text-white">
              <i className="las la-phone-volume mr-2 text-base"></i>
              ติดต่อสอบถาม {systemContactUsTell}
            </p>
            <div className="flex items-center gap-10 font-medium text-black dark:text-white">
              <p className="cursor-pointer text-xs">ติดตามสถานะการซื้อ</p>
              <div className=" flex gap-2">
                {user ? (
                  <>
                    <div className="bg-slate-200 dark:bg-slate-900 rounded-md p-1 px-2  dark:hover:bg-slate-500 duration-300">
                      <button className="text-xs flex items-center cursor-default">
                          <i className="las la-user text-xl mr-2"></i>คุณ {user.firstname} {user.lastname}
                      </button>
                    </div>
                    <div className="bg-slate-200 dark:bg-slate-900 rounded-md p-1 px-2 cursor-pointer hover:bg-red-600 hover:text-white dark:hover:bg-red-500 duration-300">
                      <button className="text-xs flex items-center" onClick={logout}>
                        <i className="las la-sign-out-alt text-xl mr-2"></i>ออกจากระบบ
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="bg-slate-200 dark:bg-slate-900 rounded-md p-1 px-2 cursor-pointer hover:bg-slate-600 hover:text-white dark:hover:bg-slate-500 duration-300">
                      <a className="text-xs flex items-center" href="/login">
                        <i className="las la-user text-xl mr-2"></i>เข้าสู่ระบบ
                      </a>
                    </div>
                    <div className="bg-slate-200 dark:bg-slate-900 rounded-md p-1 px-2 cursor-pointer hover:bg-slate-600 hover:text-white dark:hover:bg-slate-500 duration-300">
                      <a className="text-xs flex items-center" href="/signup">
                        <i className="las la-user text-xl mr-2"></i>สมัครสมาชิก
                      </a>
                    </div>
                  </>
                )}


              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sticky MainNav2Logged */}
      <div
        className={`nc-MainNav2Logged z-40 w-full ${isNavSticky ? "fixed top-0 shadow-lg lg:mt-0" : "relative lg:mt-8"
          }`}
      >
        <MainNav2Logged />
      </div>

    </div>
  );
};

export default HeaderLogged;
