import React, { useState, useEffect } from 'react';

interface ImageData {
  url: string;
  link: string;
}

const PromotionImageSlider = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetch('/data/dataImagePromotionSlice.json')
      .then((response) => response.json())
      .then((data: ImageData[]) => {
        setImages(data);
      })
      .catch((error) => {
        console.error('Error fetching image data:', error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="relative max-w-xl mx-1 h-auto overflow-hidden rounded-lg bg-gray-200">
      {images.map((image, index) => (
        index === currentIndex && (
          <a key={index} href={image.link}>
            <img
              src={image.url}
              alt={`Slide ${index + 1}`}
              className="w-full h-auto object-cover opacity-100 hover:opacity-70 transition-opacity duration-200 rounded-lg"
            />
          </a>
        )
      ))}
      <div className="absolute left-0 right-0 flex justify-center space-x-2 lg:bottom-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full hover:opacity-70 ${index === currentIndex ? 'bg-white' : 'bg-gray-400'
              }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default PromotionImageSlider;
