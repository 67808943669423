import React, { useState } from "react";
import CommonLayout from "./CommonLayout";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import PopupAdd from "./PopupAdd";

const AccountAddress = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // สถานะสำหรับ input fields
  const [firstName, setFirstName] = useState("Cole");
  const [lastName, setLastName] = useState("Enrico");
  const [phoneNumber, setPhoneNumber] = useState("091234567");
  const [addressDetail, setAddressDetail] = useState("123, Dream Avenue, USA");
  const [building, setBuilding] = useState("55U - DD5");
  const [district, setDistrict] = useState("Norris");
  const [province, setProvince] = useState("Texas");
  const [postalCode, setPostalCode] = useState("2500");
  const [addressType, setAddressType] = useState("Address-type-home");

  const toggleAddressDetails = () => {
    setIsOpen(!isOpen);
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const renderAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden">
        <div className="flex justify-between items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">ที่อยู่จัดส่ง</p>
            <p className="text-slate-600 dark:text-slate-400 text-sm mt-1.5 sm:mt-2 font-semibold">
              กนกกร นิธิธรรมรงค์
              <span className="pl-4">{phoneNumber}</span>
            </p>

            <p className="text-slate-600 dark:text-slate-400 text-sm mt-1.5 sm:mt-2 font-semibold">
              {addressDetail}, {district}, {province}, {postalCode}
            </p>
          </div>
          <ButtonSecondary
            sizeClass="py-2.5 px-4 sm:px-6"
            fontSize="text-sm font-medium"
            onClick={toggleAddressDetails}
          >
            {isOpen ? "ซ่อนที่อยู่" : "ดูรายละเอียดที่อยู่"}
          </ButtonSecondary>
        </div>

        {isOpen && (
          <div className="border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  ชื่อจริง
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-user"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={firstName} />
                </div>
              </div>
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  นามสกุล
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-user"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={lastName} />
                </div>
              </div>
            </div>
            <div>
              <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                เบอร์โทรศัพท์
              </label>
              <div className="mt-1.5 flex w-full md:w-1/2 ">
                <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                  <i className="text-2xl las la-phone"></i>
                </span>
                <Input className="!rounded-l-none" defaultValue={phoneNumber} />
              </div>
            </div>

            <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="flex-1">
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  รายละเอียดที่อยู่
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-map-marked-alt"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={addressDetail} />
                </div>
              </div>
              <div className="sm:w-1/3">
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  ชั้น, ตึก *
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-building"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={building} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  ตำบล
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-hotel"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={district} />
                </div>
              </div>
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  อำเภอ
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-hotel"></i>
                  </span>
                  <select
                    className="h-11 px-4 py-3 text-sm font-normal block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800"
                    defaultValue="United States"
                  >
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Israel">Israel</option>
                    <option value="France">France</option>
                    <option value="England">England</option>
                    <option value="Laos">Laos</option>
                    <option value="China">China</option>
                  </select>
                </div>
              </div>
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  จังหวัด
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-city"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={province} />
                </div>
              </div>
              <div>
                <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                  รหัสไปรษณีย์
                </label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-map-signs"></i>
                  </span>
                  <Input className="!rounded-l-none" defaultValue={postalCode} />
                </div>
              </div>
            </div>

            <div>
              <label className="nc-Label font-medium text-neutral-900 dark:text-neutral-200 text-sm">
                ประเภทที่อยู่
              </label>
              <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                <div className="flex items-center text-sm sm:text-base">
                  <input
                    id="Address-type-home"
                    name="Address-type"
                    type="radio"
                    className="text-primary-500 rounded-full border-slate-400 dark:border-slate-700 w-6 h-6"
                    value="Address-type-home"
                    checked={addressType === "Address-type-home"}
                    onChange={() => setAddressType("Address-type-home")}
                  />
                  <label
                    htmlFor="Address-type-home"
                    className="pl-2.5 sm:pl-3 block text-slate-900 dark:text-slate-100"
                  >
                    บ้าน <span className="font-light">(จัดส่งทั้งวัน)</span>
                  </label>
                </div>
                <div className="flex items-center text-sm sm:text-base">
                  <input
                    id="Address-type-office"
                    name="Address-type"
                    type="radio"
                    className="text-primary-500 rounded-full border-slate-400 dark:border-slate-700 w-6 h-6"
                    value="Address-type-office"
                    checked={addressType === "Address-type-office"}
                    onChange={() => setAddressType("Address-type-office")}
                  />
                  <label
                    htmlFor="Address-type-office"
                    className="pl-2.5 sm:pl-3 block text-slate-900 dark:text-slate-100"
                  >
                    ที่ทำงาน <span className="font-light">(จัดส่งในเวลางาน)</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row pt-6">
              <button className="nc-Button bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 rounded-full py-3 px-6 hover:bg-slate-800">
                <i className="las la-edit mr-2 mb-1"></i>
                บันทึกการแก้ไข
              </button>
              <button className="nc-Button mt-3 sm:mt-0 sm:ml-3 bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-300 rounded-full py-3 px-6 hover:bg-gray-100">
                Cancel
              </button>
            </div>
          </div>

        )}
      </div>
    );
  };

  const renderPopup = () => {
    return (
      isPopupOpen && (
        <PopupAdd togglePopup={togglePopup} isOpen={isPopupOpen} />
      )
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <div className="flex justify-between">
            <h2 className="text-2xl sm:text-3xl font-semibold">
              ที่อยู่สำหรับจัดส่ง
            </h2>
            <button className="flex gap-2 items-center" onClick={togglePopup}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
              </svg>
              เพิ่มที่อยู่ใหม่
            </button>
          </div>
          {renderAddress()}
        </div>
        {renderPopup()}
      </CommonLayout>
    </div>
  )
};

export default AccountAddress;
