import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Promobar from "components/Promobar";
import ProductCardCustom from "components/ProductCardCustom";
import { useOptions } from "./OptionsContext";

export interface PageSearchProps {
  className?: string;
}

interface Product {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
  category: string;
  tags: string[];
  link: string;
  rating: string;
  reviews: string;
  saleStatus: number;
  oldprice: number;
  score:number;
  allOfSizes:[];
  sizes:[];
  status:string;
  detail:string;
  

}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { getOptionByName } = useOptions();
  const basePath = getOptionByName('basePathAdmin');

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('q') || '';

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${basePath}api/getProduct/frontEnd/productTest.php`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `query=${encodeURIComponent(searchQuery)}`,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data && data.results && data.results.product) {
          setSearchResults(data.results.product);
        } else {
          setSearchResults([]);
        }
      } catch (err) {
        setError('Failed to fetch search results');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
      setLoading(false);
    }
  }, [searchQuery]);

  return (
    <div className={`nc-PageSearch mt-22 mt-22 pt-20 ${className}`} data-nc-id="PageSearch">
      <Promobar />

      <div className="container py-16 lg:pb-28 lg:pt-12 space-y-16 lg:space-y-28">
        <main>
          <h2 className="font-semibold text-2xl">ผลการค้นหาสำหรับ : <span className="text-red-500">{searchQuery} </span>  {searchResults.length > 0 && (
              <span className="text-slate-600 text-lg"> ({searchResults.length} สินค้า)</span>
            )}</h2>
          
          <div className="flex-1 mt-8 lg:mt-10">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8 sm:gap-x-8 sm:gap-y-10 mt-8 lg:mt-10">
              {loading ? (
                <p className="col-span-full">กำลังโหลด...</p>
              ) : error ? (
                <p className="col-span-full">เกิดข้อผิดพลาด: {error}</p>
              ) : searchResults.length > 0 ? (
                searchResults.map((product) => (
                  <ProductCardCustom key={product.id} product={product} />
                ))
              ) : (
                <p className="col-span-full text-center">ไม่พบสินค้าที่ตรงกับคำค้นหา</p>
              )}
            </div>
          </div>

          {searchResults.length > 0 && (
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary>แสดงเพิ่มเติม</ButtonPrimary>
            </div>
          )}
        </main>

        <hr className="border-slate-200 dark:border-slate-700" />
        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" />
      </div>
    </div>
  );
};

export default PageSearch;