import React, { useState, useEffect } from 'react';
import { useOptions } from 'containers/OptionsContext';

interface Category {
    id: number;
    name: string;
    subcategories: string[];
    images212: string[];
    images450: string;
    images: string;
}

const MultilevelDropdownMobile: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const { getOptionByName } = useOptions();
    const basePath = getOptionByName('siteurl');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${basePath}api/categoryHome`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                });

                const data = await response.json();
                setCategories(data.results.category);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        if (basePath) {
            fetchCategories();
        }
    }, [basePath]);

    return (
        <div className="">
            <div className="flex overflow-x-auto gap-3 md:gap-4 lg:gap-3 customScrollBar">
                {categories.map((category) => (
                    <a href='#' key={category.id} className="flex-shrink-0 flex flex-col items-center text-center">
                        <img
                            src={category.images}
                            alt={category.name}
                            className="w-24 lg:w-44 h-24 lg:h-44 object-cover rounded-lg"
                        />
                        <p className="mt-2 text-sm font-medium truncate w-24 lg:w-44">
                            {category.name}
                        </p>
                    </a>
                ))}
            </div>

        </div>
    );
}

export default MultilevelDropdownMobile;
